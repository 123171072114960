import store from '@/store'
import router from '@/router'

const GENERAL_HOOK = process.env.VUE_APP_GENERAL_HOOK
const GENERAL_API_HOOK = `${GENERAL_HOOK}/api/`

const GENERAL_BLOG_API_HOOK = `${GENERAL_API_HOOK}blog/`
const NEWS_API_HOOK = `${GENERAL_BLOG_API_HOOK}news/`

const GENERAL_GEN_ADMIN_API_HOOK = `${GENERAL_API_HOOK}gen_admin/`

const AVAILABLE_TASKS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks/`
const AVAILABLE_NEW_TASKS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_new/`
const AVAILABLE_TASKS_FROM_COLUMN_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_column/`
const AVAILABLE_TASKS_FROM_REPORT_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_report/`
const AVAILABLE_TASKS_FROM_OFFLINE_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_report_and_offline/`
const AVAILABLE_TASKS_FROM_PROPOSE_TO_ANOTHER_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_propose/`
const AVAILABLE_TASKS_FROM_AUTO_PROPOSE_TO_ANOTHER_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_auto_propose/`
const AVAILABLE_TASKS_FROM_INTERVIEW_PROPOSE_TO_ANOTHER_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_interview_propose/`
const AVAILABLE_TASKS_FROM_AUTO_PROPOSE_FROM_OFFLINE_VIEWING_TO_ANOTHER_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_auto_propose_from_offline_viewing/`
const AVAILABLE_TASKS_FROM_INTERVIEW_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_interview/`
const AVAILABLE_TASKS_FROM_ARCHIVE_COLUMN_TO_ANOTHER_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}archive_column/`
const AVAILABLE_TASKS_FROM_DOCUMENT_MANAGEMENT_COLUMN_TO_ANOTHER_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}document_management/`
const AVAILABLE_TASKS_FROM_INTERVIEW_COLUMN_TO_ANOTHER_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}interview_column/`
const ADMIN_TASKS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}admin_tasks/`
const EXAMPLE_TASKS = `${GENERAL_GEN_ADMIN_API_HOOK}available_tasks_example/`
const TASK_ASSIGN_TO_SELF_API_HOOK = `${AVAILABLE_TASKS_API_HOOK}assign_task_to_self/`
const TASK_ASSIGN_NEW_TO_SELF_API_HOOK = `${AVAILABLE_NEW_TASKS_API_HOOK}assign_task_to_self/`
const TASK_ASSIGN_COLUMN_TO_SELF_API_HOOK = `${AVAILABLE_TASKS_FROM_COLUMN_API_HOOK}assign_task_to_self/`
const TASK_ASSIGN_REPORT_TO_SELF_API_HOOK = `${AVAILABLE_TASKS_FROM_REPORT_API_HOOK}assign_task_to_self/`
const TASK_ASSIGN_INTERVIEW_TO_SELF_API_HOOK = `${AVAILABLE_TASKS_FROM_INTERVIEW_API_HOOK}assign_task_to_self/`
const TASK_OFFLINE_ASSIGN_REPORT_TO_SELF_API_HOOK = `${AVAILABLE_TASKS_FROM_OFFLINE_API_HOOK}assign_task_to_self/`
const TASK_ASSIGN_PROPOSE_TO_ANOTHER_TO_SELF_API_HOOK = `${AVAILABLE_TASKS_FROM_PROPOSE_TO_ANOTHER_API_HOOK}assign_task_to_self/`
const ADMIN_TASK_ASSIGN_TO_ANOTHER_API_HOOK = `${ADMIN_TASKS_API_HOOK}send_task_to_freelancer/`
const TASK_ASSIGN_INTERVIEW_PROPOSE_TO_ANOTHER_TO_SELF_API_HOOK = `${AVAILABLE_TASKS_FROM_INTERVIEW_PROPOSE_TO_ANOTHER_API_HOOK}assign_task_to_self/`
const TASK_ASSIGN_AUTO_PROPOSE_TO_ANOTHER_TO_SELF_API_HOOK = `${AVAILABLE_TASKS_FROM_AUTO_PROPOSE_TO_ANOTHER_API_HOOK}assign_task_to_self/`
const TASK_ASSIGN_AUTO_FROM_OFFLINE_VIEWING_PROPOSE_TO_ANOTHER_TO_SELF_API_HOOK = `${AVAILABLE_TASKS_FROM_AUTO_PROPOSE_FROM_OFFLINE_VIEWING_TO_ANOTHER_API_HOOK}assign_task_to_self/`

function GET_AVAILABLE_FROM_ARCHIVE_COLUMN_TO_ANOTHER_TASK_DETAILS_API_HOOK(
  task_id,
) {
  return `${AVAILABLE_TASKS_FROM_ARCHIVE_COLUMN_TO_ANOTHER_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_FROM_DOCUMENT_MANAGEMENT_TO_ANOTHER_TASK_DETAILS_API_HOOK(
  task_id,
) {
  return `${AVAILABLE_TASKS_FROM_DOCUMENT_MANAGEMENT_COLUMN_TO_ANOTHER_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_FROM_INTERVIEW_COLUMN_TO_ANOTHER_TASK_DETAILS_API_HOOK(
  task_id,
) {
  return `${AVAILABLE_TASKS_FROM_INTERVIEW_COLUMN_TO_ANOTHER_API_HOOK}${task_id}/`
}

function GET_ADMIN_TASK_DETAILS_API_HOOK(task_id) {
  return `${ADMIN_TASKS_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_FROM_PROPOSE_TO_ANOTHER_TASK_DETAILS_API_HOOK(task_id) {
  return `${AVAILABLE_TASKS_FROM_PROPOSE_TO_ANOTHER_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_FROM_AUTO_PROPOSE_TO_ANOTHER_TASK_DETAILS_API_HOOK(
  task_id,
) {
  return `${AVAILABLE_TASKS_FROM_AUTO_PROPOSE_TO_ANOTHER_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_TASK_DETAILS_API_HOOK(task_id) {
  return `${AVAILABLE_TASKS_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_FROM_INTERVIEW_PROPOSE_TO_ANOTHER_TASK_DETAILS_API_HOOK(
  task_id,
) {
  return `${AVAILABLE_TASKS_FROM_INTERVIEW_PROPOSE_TO_ANOTHER_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_NEW_TASK_DETAILS_API_HOOK(task_id) {
  return `${AVAILABLE_NEW_TASKS_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_FROM_COLUMN_TASK_DETAILS_API_HOOK(task_id) {
  return `${AVAILABLE_TASKS_FROM_COLUMN_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_FROM_REPORT_TASK_DETAILS_API_HOOK(task_id) {
  return `${AVAILABLE_TASKS_FROM_REPORT_API_HOOK}${task_id}/`
}
function GET_AVAILABLE_FROM_INTERVIEW_TASK_DETAILS_API_HOOK(task_id) {
  return `${AVAILABLE_TASKS_FROM_INTERVIEW_API_HOOK}${task_id}/`
}
const MY_TASKS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}user_tasks/`

const MY_OFFLINE_TASKS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}user_offline_tasks/`
const MY_INTERVIEW_TASKS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}user_interview_tasks/`

const GET_MY_PROFILE_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}usergentasksprofile/`
const GET_DATA_FOR_QUERY_TYPE_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}data_for_query_field/`
const MANAGER_GET_BOARD = `${GENERAL_GEN_ADMIN_API_HOOK}get_board/`
const ADMIN_PANEL_FOR_BOARD = `${GENERAL_GEN_ADMIN_API_HOOK}admin_panel/`
const MANAGER_GET_PERMISSIONS = `${GENERAL_GEN_ADMIN_API_HOOK}get_permissions/`
const GET_ARCHIVE_INFO_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}archiveinfo/`
const GET_FREELANCERS_LIST_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}freelancers_list/`
const TEXT_ENHANCEMENT_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}text_enhancement/`
const ASSESSMENT_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}assessment/`
const ASSIGN_RESPONSIBILITY_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}assign_responsibility/`
const GET_DATA_FOR_ZA_TASK_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}get_za_data/`
const SEND_ARCHIVE_DATA_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}send_archive_data/`
const CREATE_OP_TASK_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}create_bitrix_task/`
const COMPLEX_TASK_GET_DATA_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}create_bitrix_task/get_data/`
const BUSINESS_PROCESS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}business_process/`
const TEXT_RECOGNITION_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}text_recognition/`
function GET_DATA_FOR_ZA_TASK__DETAILS_API_HOOK(task_id) {
  return `${GET_DATA_FOR_ZA_TASK_API_HOOK}${task_id}/`
}

function GENERATE_TEXT_FOR_ARCHIVE_MANAGEMENT(task_id) {
  return `${SEND_ARCHIVE_DATA_API_HOOK}${task_id}/generate_text/`
}

function GENERATE_PDF_FOR_ARCHIVE_MANAGEMENT(task_id) {
  return `${SEND_ARCHIVE_DATA_API_HOOK}${task_id}/generate_pdf/`
}
function PARSE_FILE_API_HOOK(task_id) {
  return `${BUSINESS_PROCESS_API_HOOK}${task_id}/parse_file/`
}
function TEXT_RECOGNITION_FUNC_API_HOOK(task_id) {
  return `${TEXT_RECOGNITION_API_HOOK}${task_id}/text_recognition/`
}
function CREATE_BP_API_HOOK(task_id) {
  return `${BUSINESS_PROCESS_API_HOOK}${task_id}/create_bp/`
}

function GET_MY_TASK_DETAILS_API_HOOK(task_id) {
  return `${MY_TASKS_API_HOOK}${task_id}/`
}
function GET_MY_OFFLINE_TASK_DETAILS_API_HOOK(task_id) {
  return `${MY_OFFLINE_TASKS_API_HOOK}${task_id}/`
}
function GET_MY_INTERVIEW_TASK_DETAILS_API_HOOK(task_id) {
  return `${MY_INTERVIEW_TASKS_API_HOOK}${task_id}/`
}

const EXAMPLE_MY_TASKS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}user_example_tasks/`

function GET_MY_EXAMPLE_TASK_DETAILS_API_HOOK(task_id) {
  return `${EXAMPLE_MY_TASKS_API_HOOK}${task_id}/`
}

function USER_GET_MONGO_DATA(task_id) {
  return `${GET_MY_TASK_DETAILS_API_HOOK(task_id)}manipulate_data/`
}

function GET_MY_TASK_MARK_AS_AWAITING_CHECK_API_HOOK(task_id) {
  return `${GET_MY_TASK_DETAILS_API_HOOK(task_id)}mark_task_as_awaiting_check/`
}

// function USER_GET_MONGO_DATA_FOR_OFFLINE(task_id) {
//   return `${GET_MY_OFFLINE_TASK_DETAILS_API_HOOK(task_id)}manipulate_data/`
// }

function GET_MY_OFFLINE_TASK_MARK_AS_AWAITING_CHECK_API_HOOK(task_id) {
  return `${GET_MY_OFFLINE_TASK_DETAILS_API_HOOK(
    task_id,
  )}mark_task_as_awaiting_check/`
}

function GET_MY_INTERVIEW_TASK_MARK_AS_AWAITING_CHECK_API_HOOK(task_id) {
  return `${GET_MY_INTERVIEW_TASK_DETAILS_API_HOOK(
    task_id,
  )}mark_task_as_awaiting_check/`
}

const MY_ANSWERS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}user_answers/`

function GET_MY_ANSWER_BY_ID_API_HOOK(answer_id) {
  return `${MY_ANSWERS_API_HOOK}${answer_id}/`
}

const TASKS_FOR_CHECK_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}manager_tasks/`
const CREATE_CLIENT_MESSAGE = `${GENERAL_GEN_ADMIN_API_HOOK}create_client_message/`

const OFFLINE_TASKS_FOR_CHECK_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}manager_offline_tasks/`
const INTERVIEW_TASKS_FOR_CHECK_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}manager_interview_tasks/`

function GET_TASK_FOR_CHECK_DETAILS_API_HOOK(task_id) {
  return `${TASKS_FOR_CHECK_API_HOOK}${task_id}/`
}

function CREATE_CLIENT_MESSAGE_API_HOOK(task_id) {
  return `${CREATE_CLIENT_MESSAGE}${task_id}/`
}

function GET_TASK_FOR_CHECK_OFFLINE_DETAILS_API_HOOK(task_id) {
  return `${OFFLINE_TASKS_FOR_CHECK_API_HOOK}${task_id}/`
}

function GET_TASK_FOR_CHECK_INTERVIEW_DETAILS_API_HOOK(task_id) {
  return `${INTERVIEW_TASKS_FOR_CHECK_API_HOOK}${task_id}/`
}

function MANAGER_GET_MONGO_DATA(task_id) {
  return `${GET_TASK_FOR_CHECK_DETAILS_API_HOOK(task_id)}manipulate_data/`
}

function GET_TASK_FOR_CHECK_ACCEPT_REJECT_API_HOOK(task_id) {
  return `${GET_TASK_FOR_CHECK_DETAILS_API_HOOK(task_id)}accept_reject_task/`
}

function GET_TASK_FOR_CHECK_OFFLINE_ACCEPT_REJECT_API_HOOK(task_id) {
  return `${GET_TASK_FOR_CHECK_OFFLINE_DETAILS_API_HOOK(
    task_id,
  )}accept_reject_task/`
}

function GET_TASK_FOR_CHECK_INTERVIEW_ACCEPT_REJECT_API_HOOK(task_id) {
  return `${GET_TASK_FOR_CHECK_INTERVIEW_DETAILS_API_HOOK(
    task_id,
  )}accept_reject_task/`
}
const MANAGER_ANSWERS_API_HOOK = `${GENERAL_GEN_ADMIN_API_HOOK}manager_answers/`

function GET_MANAGER_ANSWER_BY_ID_API_HOOK(answer_id) {
  return `${MANAGER_ANSWERS_API_HOOK}${answer_id}/`
}

const GENERAL_ACCOUNT_HOOK = `${GENERAL_HOOK}/auth/`
const AUTH_GET_TOKEN_API = `${GENERAL_ACCOUNT_HOOK}token/`
const AUTH_REFRESH_TOKEN_API = `${GENERAL_ACCOUNT_HOOK}token/refresh/`
const AUTH_BLACKLIST_TOKEN_API = `${GENERAL_ACCOUNT_HOOK}token/blacklist/`

async function get_form_data_init(data, method = 'POST') {
  const form_data = new FormData()
  for (const [key, value] of Object.entries(data)) {
    form_data.append(key, value)
  }

  return {
    method: method,
    headers: {
      Authorization: await store.dispatch('getAuthHeader'),
    },
    mode: 'cors',
    body: form_data,
  }
}

async function fetch_api_json(
  link,
  method = 'GET',
  body_data = null,
  init = null,
) {
  if (!init) {
    init = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: await store.dispatch('getAuthHeader'),
      },
      mode: 'cors',
    }
    if (body_data) {
      init.body = JSON.stringify(body_data)
    }
  }
  let res = await fetch(link, init)
  if (res.status === 401) {
    // Not authenticated
    window.location.href = router.resolve({ name: 'Logout' }).href
    window.location.reload()
    return null
  } else if (res.status === 404 && process.env.VUE_APP_REDIRECT_ON_ERROR) {
    // Page not found
    window.location.href = router.resolve({ name: 'Page404' }).href
    window.location.reload()
  } else if (res.status === 403) {
    // Doesn't have permission
    return null
  }
  return res
}

async function fetch_auth_json(
  link,
  method = 'POST',
  body_data = null,
  init = null,
) {
  if (!init) {
    init = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    }
    if (body_data) {
      init.body = JSON.stringify(body_data)
    }
  }
  return await fetch(link, init)
}

export {
  fetch_api_json,
  fetch_auth_json,
  get_form_data_init,
  NEWS_API_HOOK,
  AVAILABLE_TASKS_API_HOOK,
  GET_AVAILABLE_TASK_DETAILS_API_HOOK,
  TASK_ASSIGN_TO_SELF_API_HOOK,
  AVAILABLE_NEW_TASKS_API_HOOK,
  EXAMPLE_TASKS,
  GET_AVAILABLE_NEW_TASK_DETAILS_API_HOOK,
  AVAILABLE_TASKS_FROM_COLUMN_API_HOOK,
  TASK_ASSIGN_AUTO_FROM_OFFLINE_VIEWING_PROPOSE_TO_ANOTHER_TO_SELF_API_HOOK,
  GET_AVAILABLE_FROM_COLUMN_TASK_DETAILS_API_HOOK,
  TASK_ASSIGN_NEW_TO_SELF_API_HOOK,
  TEXT_ENHANCEMENT_API_HOOK,
  TASK_ASSIGN_COLUMN_TO_SELF_API_HOOK,
  GET_AVAILABLE_FROM_REPORT_TASK_DETAILS_API_HOOK,
  TASK_ASSIGN_REPORT_TO_SELF_API_HOOK,
  GET_AVAILABLE_FROM_DOCUMENT_MANAGEMENT_TO_ANOTHER_TASK_DETAILS_API_HOOK,
  TASK_OFFLINE_ASSIGN_REPORT_TO_SELF_API_HOOK,
  AVAILABLE_TASKS_FROM_REPORT_API_HOOK,
  AVAILABLE_TASKS_FROM_INTERVIEW_API_HOOK,
  GET_AVAILABLE_FROM_INTERVIEW_TASK_DETAILS_API_HOOK,
  TASK_ASSIGN_INTERVIEW_TO_SELF_API_HOOK,
  MY_TASKS_API_HOOK,
  ASSIGN_RESPONSIBILITY_API_HOOK,
  EXAMPLE_MY_TASKS_API_HOOK,
  MANAGER_GET_BOARD,
  ADMIN_PANEL_FOR_BOARD,
  BUSINESS_PROCESS_API_HOOK,
  GET_AVAILABLE_FROM_ARCHIVE_COLUMN_TO_ANOTHER_TASK_DETAILS_API_HOOK,
  PARSE_FILE_API_HOOK,
  CREATE_BP_API_HOOK,
  MANAGER_GET_PERMISSIONS,
  GET_MY_PROFILE_API_HOOK,
  TEXT_RECOGNITION_API_HOOK,
  TEXT_RECOGNITION_FUNC_API_HOOK,
  GET_DATA_FOR_QUERY_TYPE_API_HOOK,
  GET_MY_EXAMPLE_TASK_DETAILS_API_HOOK,
  GET_MY_TASK_DETAILS_API_HOOK,
  GET_MY_OFFLINE_TASK_DETAILS_API_HOOK,
  GET_MY_TASK_MARK_AS_AWAITING_CHECK_API_HOOK,
  GET_MY_OFFLINE_TASK_MARK_AS_AWAITING_CHECK_API_HOOK,
  GET_MY_INTERVIEW_TASK_MARK_AS_AWAITING_CHECK_API_HOOK,
  GET_TASK_FOR_CHECK_INTERVIEW_ACCEPT_REJECT_API_HOOK,
  USER_GET_MONGO_DATA,
  GET_DATA_FOR_ZA_TASK__DETAILS_API_HOOK,
  MANAGER_GET_MONGO_DATA,
  MY_ANSWERS_API_HOOK,
  GET_MY_ANSWER_BY_ID_API_HOOK,
  COMPLEX_TASK_GET_DATA_API_HOOK,
  TASKS_FOR_CHECK_API_HOOK,
  CREATE_OP_TASK_API_HOOK,
  OFFLINE_TASKS_FOR_CHECK_API_HOOK,
  GET_TASK_FOR_CHECK_DETAILS_API_HOOK,
  SEND_ARCHIVE_DATA_API_HOOK,
  GET_AVAILABLE_FROM_INTERVIEW_PROPOSE_TO_ANOTHER_TASK_DETAILS_API_HOOK,
  TASK_ASSIGN_INTERVIEW_PROPOSE_TO_ANOTHER_TO_SELF_API_HOOK,
  GENERATE_TEXT_FOR_ARCHIVE_MANAGEMENT,
  GENERATE_PDF_FOR_ARCHIVE_MANAGEMENT,
  GET_TASK_FOR_CHECK_OFFLINE_DETAILS_API_HOOK,
  GET_TASK_FOR_CHECK_ACCEPT_REJECT_API_HOOK,
  GET_TASK_FOR_CHECK_OFFLINE_ACCEPT_REJECT_API_HOOK,
  MANAGER_ANSWERS_API_HOOK,
  GET_FREELANCERS_LIST_API_HOOK,
  GET_MANAGER_ANSWER_BY_ID_API_HOOK,
  AUTH_GET_TOKEN_API,
  ASSESSMENT_API_HOOK,
  AUTH_BLACKLIST_TOKEN_API,
  AUTH_REFRESH_TOKEN_API,
  CREATE_CLIENT_MESSAGE_API_HOOK,
  ADMIN_TASKS_API_HOOK,
  GET_AVAILABLE_FROM_INTERVIEW_COLUMN_TO_ANOTHER_TASK_DETAILS_API_HOOK,
  GET_ARCHIVE_INFO_API_HOOK,
  GET_ADMIN_TASK_DETAILS_API_HOOK,
  ADMIN_TASK_ASSIGN_TO_ANOTHER_API_HOOK,
  AVAILABLE_TASKS_FROM_PROPOSE_TO_ANOTHER_API_HOOK,
  TASK_ASSIGN_PROPOSE_TO_ANOTHER_TO_SELF_API_HOOK,
  GET_AVAILABLE_FROM_PROPOSE_TO_ANOTHER_TASK_DETAILS_API_HOOK,
  TASK_ASSIGN_AUTO_PROPOSE_TO_ANOTHER_TO_SELF_API_HOOK,
  GET_AVAILABLE_FROM_AUTO_PROPOSE_TO_ANOTHER_TASK_DETAILS_API_HOOK,
}
